import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import isExternalLink from "../../utils/isExternalLink"

const InOutLink = ({
  to,
  className,
  activeClassName,
  style,
  onClick,
  children,
  target,
}) => {
  if (isExternalLink(to)) {
    return (
      <OutboundLink
        href={to}
        className={className}
        style={style}
        rel="noopener noreferrer"
        target={target == undefined ? "_blank" : target}
      >
        {children}
      </OutboundLink>
    )
  }

  return (
    <Link
      to={to}
      className={className}
      activeClassName={activeClassName}
      style={style}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

InOutLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default InOutLink
