import React from "react"
import { Link } from "gatsby"

const ImmersionLayout = props => {
  const {
    title,
    hideLogo,
    children,
    subtitle,
    headStyle,
    backgroundColor,
    backgroundVideo,
  } = props
  // const [toggleNav, setToggleNav] = React.useState(false)
  const toggleNav = false

  return (
    <div
      className={`site-wrapper size-immersion ${
        toggleNav ? `site-head-open` : ``
      }`}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <header className="site-head site-head-dark">
        {!hideLogo && (
          <div className="site-head-container">
            <div className="site-head-left">
              <Link className="site-head-logo" to={`/`}>
                {title}
              </Link>
            </div>
            <div className="site-head-center">
              <Link className="site-head-logo" to={`/`}>
                <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
              </Link>
            </div>
            <div className="site-head-left">
              <Link className="site-head-logo" to={`/`}>
                {title}
              </Link>
            </div>
          </div>
        )}
      </header>
      <main id="site-main" className="site-main size-immersion">
        {backgroundVideo && (
          <video
            tabIndex="0"
            className={`video-background`}
            autobuffer="autobuffer"
            preload="preload"
            autoPlay
            muted="muted"
            playsinline
            loop
          >
            <source type="video/mp4" src={backgroundVideo} />
          </video>
        )}
        <div id="swup" className="fade-in-fast size-immersion">
          {children}
        </div>
      </main>

      {/*       
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer> */}
    </div>
  )
}

export default ImmersionLayout
